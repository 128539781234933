import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/core'
import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import {
  BLACK,
  GRAY_LIGHT,
  GRAY_MEDIUM_LIGHT,
  MAIN_FONT_FAMILY,
  WHITE,
  GRAY_MEDIUM_DARK
} from '../../../constants/theme.styles'

import { InputProps } from '../Input/Input'
import { Country, G_AUTOCOMPLETE_COUNTRY_LONG_TO_SHORT } from '../../../constants/app-constants'
import InputBold from '../Input/InputBold'
import { LangContext } from '~/context/LangContext'
import { searchLocationPlaceholder } from '~/lang/langFile'

const LocationListStyles = css`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 2px;
  top: 100%;
  border: ${`1px solid ${GRAY_LIGHT}`};
  border-radius: 7px;
  overflow: hidden;
  background: ${WHITE};
`

const LocationListItemStyles = css`
  color: ${GRAY_MEDIUM_DARK};
  font-family: ${MAIN_FONT_FAMILY};
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  padding: 8px 15px;
  margin: 5px 0;
  cursor: pointer;
  z-index: 300;
  :hover {
    background-color: ${GRAY_MEDIUM_LIGHT};
  }

  b {
    font-weight: 700;
    color: ${BLACK};
  }
`

const PowerByGoogle = styled.div`
  width: 100%;
  height: 20px;

  &:after {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    background: url('${PUBLIC_ASSET_PREFIX}/static/icons/powered_by_google_on_white.png') no-repeat;
    background-position: 99% center;
    background-size: 100px;
  }
`

const Bold = styled.b({
  fontWeight: 'bold',
  color: BLACK
})

const toplineStyles = css`
  display: none;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid ${GRAY_LIGHT};
`

const fluidStyles = css`
  display: block;
  width: 100%;
  max-width: 100%;
`

interface LocationInputUiProps {
  id: string
  value: any
  name?: string
  loadingSpinner?: 'enabled' | 'disabled'
  onChange?: (name: string, value: string) => void
  onSelect?: ({
    inputName,
    address,
    locality,
    area1,
    searchCountry,
    placeid
  }: {
    inputName: string
    address: string
    locality?: string
    area1?: string
    searchCountry: string
    placeid: string
  }) => void
  onEnterKeySubmitAction?: ({
    inputName,
    address,
    locality,
    area1,
    searchCountry,
    placeid
  }: {
    inputName: string
    address: string
    locality?: string
    area1?: string
    searchCountry: string
    placeid: string
  }) => void
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void
  inputRef?: React.RefObject<HTMLInputElement>
  inputElement?: React.ReactElement<any>
  inputTestKey?: string
  wrapperCss?: SerializedStyles
  placeholder?: string
  listWrapperCss?: SerializedStyles
  topLineCss?: SerializedStyles
  fluid?: boolean
  // attachInputProps really only used for attach component
  attachInputProps?: InputProps
  countryRestrictions?: string[]
  isPlacesAutocompleteABTest?: boolean
  returnType?: 'locality' | 'neighborhood' | 'postal_code'
}

class LocationInputUi extends React.Component<LocationInputUiProps> {
  static displayName = 'LocationInputUi'
  static contextType = LangContext
  static defaultProps = {
    value: '',
    isPlacesAutocompleteABTest: true
  }

  state = {
    address: (this.props.value && this.props.value.toString()) || '',
    searchCountry: '',
    focused: false,
    placeid: ''
  }

  private suggestions: any[] = []

  private searchOptions: any = {
    types: ['(regions)'],
    componentRestrictions: {
      country: [Country.DEFAULT]
    }
  }

  handleChange = (address: string) => {
    if (address.match(/.+,.+,.+/)) {
      const match = address.match(/(.+?,.+?),/)
      address = match ? match[1] : address
    }
    this.setState({ address })
    if (this.props.onChange) {
      this.props.onChange(this.props.name || 'location', address)
    }
  }

  handleSelect = (address: string, placeid: string) => {
    if (!address && !placeid) {
      return
    }
    let searchCountry = this.state.searchCountry
    const hasSuggestions = this.suggestions.length > 0
    const activeItem = this.suggestions.find(item => item.active)
    const matchedItem = this.suggestions.find(
      item => item.description === address || item.placeid === placeid
    )

    let city: any = {}
    let state: any = {}
    let zipCode: any = {}
    let country: any = {}

    if (hasSuggestions) {
      if (matchedItem) {
        ;[city, state, zipCode, country] = matchedItem.terms
        address = matchedItem.displayText
        placeid = matchedItem.placeid
      } else if (activeItem) {
        ;[city, state, zipCode, country] = activeItem.terms
        address = activeItem.displayText
        placeid = activeItem.placeid
      } else if (hasSuggestions) {
        ;[city, state, zipCode, country] = this.suggestions[0].terms
        address = this.suggestions[0].displayText
        placeid = this.suggestions[0].placeid
      }
    } else {
      // Special case for tests, in which the country is set to the state
      // ATTN: This WILL be a problem once we start integration testing
      // i18n code, and interchangeable country testing. So, curious, why
      // in tests we don't have suggestions populated but we have `address`
      // passed in. Must be `PlacesAutocomplete` library specific thing.
      const addressParts = address.split(', ')
      city.value = addressParts[0]
      state.value = addressParts[1]
      country.value = addressParts[2]
    }

    address = address || `${city.value}${state ? ', '.concat(state.value) : ''}`
    country = country || zipCode || state

    searchCountry = G_AUTOCOMPLETE_COUNTRY_LONG_TO_SHORT[country.value] || Country.DEFAULT

    this.setState({ address, searchCountry, placeid }, () => {
      if (this.props.onSelect) {
        this.props.onSelect({
          inputName: this.props.name || 'location',
          address,
          locality: city ? city.value : '',
          area1: state ? state.value : '',
          searchCountry,
          placeid
        })
      }
    })
  }

  handleEnterKeySubmitAction = () => {
    const { address, searchCountry, placeid } = this.state
    if (typeof this.props.onEnterKeySubmitAction === 'function') {
      this.props.onEnterKeySubmitAction({
        inputName: this.props.name || 'location',
        address,
        searchCountry,
        placeid
      })
    }
  }

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ focused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus(event)
      }
    })
  }

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let searchCountry = ''
    let address = ''
    let placeid = ''

    const inputValue = event.currentTarget.value
    const hasSuggestions = this.suggestions.length > 0
    // we don't need special case since
    // here we short-circuit, and don't have `address` passed in
    if (!hasSuggestions) {
      if (this.props.onBlur) {
        this.props.onBlur(event)
      }
      return
    }
    const activeItem = this.suggestions.find(item => item.active)

    // if active item was cleared due to user hovering around with mouse
    // check if the current input value is in the suggested list and use it
    const matchedItem = this.suggestions.find(item => item.description.indexOf(inputValue) !== -1)

    let city: any = {}
    let state: any = {}
    let zipCode: any = {}
    let country: any = {}

    if (matchedItem) {
      ;[city, state, zipCode, country] = matchedItem.terms
      address = matchedItem.displayText
      placeid = matchedItem.placeid
    } else if (activeItem) {
      ;[city, state, zipCode, country] = activeItem.terms
      address = activeItem.displayText
      placeid = activeItem.placeid
    } else if (hasSuggestions) {
      ;[city, state, zipCode, country] = this.suggestions[0].terms
      address = this.suggestions[0].displayText
      placeid = this.suggestions[0].placeid
    }

    address = address || `${city.value}${state ? ', '.concat(state.value) : ''}`
    country = country || zipCode || state

    searchCountry = G_AUTOCOMPLETE_COUNTRY_LONG_TO_SHORT[country.value] || Country.DEFAULT

    this.setState({ focused: false, address, searchCountry }, () => {
      if (this.props.onSelect) {
        this.props.onSelect({
          inputName: this.props.name || 'location',
          address,
          locality: city ? city.value : '',
          area1: state ? state.value : '',
          searchCountry,
          placeid
        })
      }
      if (this.props.onBlur) {
        this.props.onBlur(event)
      }
    })
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const stateHasChanged = prevState.address !== this.state.address
    const propsHasChanged = prevProps.value !== this.props.value
    if (propsHasChanged && !stateHasChanged) {
      this.setState({ address: this.props.value ? this.props.value.toString() : '' })
    }
  }
  render() {
    const {
      id,
      inputElement,
      loadingSpinner = 'enabled',
      wrapperCss,
      listWrapperCss,
      topLineCss,
      placeholder,
      fluid,
      attachInputProps = {},
      countryRestrictions,
      returnType
    } = this.props

    if (countryRestrictions) {
      this.searchOptions.componentRestrictions.country = countryRestrictions
      if (
        countryRestrictions.length === 1 &&
        countryRestrictions.includes('us') &&
        this.props.isPlacesAutocompleteABTest === true
      ) {
        this.searchOptions.types = ['geocode']
      }
    }

    return (
      <>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={this.searchOptions}
          highlightFirstSuggestion
          googleCallbackName={id}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => {
            // remove country item (USA) from suggestion list
            suggestions = suggestions.filter(
              (item: any) => item.types.indexOf('country') === -1 && item.terms.length >= 2
            )

            if (
              this.props.isPlacesAutocompleteABTest === true &&
              suggestions.length &&
              suggestions[0].description.match(/, USA$/)
            ) {
              suggestions = suggestions.filter((item: any) => {
                if (item.types.length) {
                  for (const type of item.types) {
                    if (['locality', 'neighborhood', 'postal_code'].includes(type)) {
                      return true
                    } else {
                      return false
                    }
                  }
                }
                // filter states out of suggestions
                if (item.terms.length == 2 && item.terms[1].value === 'USA') {
                  return false
                }
                return true
              })
            }

            if (returnType) {
              suggestions = suggestions.filter((item: any) => {
                if (item.types.length) {
                  if (item.types.includes(returnType)) {
                    return true
                  } else {
                    return false
                  }
                }
                return false
              })
            }

            this.suggestions = suggestions

            const inputProps = {
              ...getInputProps({
                placeholder: placeholder || searchLocationPlaceholder(this.context.lang)[0]
              })
            }
            const allInputProps = {
              ...inputProps,
              loading: loadingSpinner === 'enabled' && loading,
              onBlur: this.handleBlur,
              onFocus: this.handleFocus,
              onKeyDown: (event: any) => {
                const suggestionsOpen = !!suggestions.length // find out if suggestions are active or not
                inputProps.onKeyDown(event) // do the normal onKeyDown callback
                if (!suggestionsOpen && event.keyCode === 13) {
                  // if this is enter key, submit form
                  this.handleEnterKeySubmitAction()
                }
              },
              icon: 'pinNew',
              iconPosition: 'left',
              inputRef: this.props.inputRef,
              'data-cy': this.props.inputTestKey
            }

            return (
              <div
                css={[
                  css`
                    position: relative;
                    background: #fff;
                    display: inline-block;
                  `,
                  fluid ? fluidStyles : null,
                  wrapperCss ? wrapperCss : null
                ]}
              >
                {inputElement ? (
                  React.cloneElement(inputElement, {
                    ...allInputProps,
                    ...inputElement.props,
                    ...attachInputProps
                  })
                ) : (
                  <InputBold {...allInputProps} {...attachInputProps} />
                )}

                {this.state.focused && suggestions.length > 0 ? (
                  <div
                    data-cy="location-input-results"
                    css={[LocationListStyles, listWrapperCss ? listWrapperCss : null]}
                  >
                    <div css={[toplineStyles, topLineCss ? topLineCss : null]} />
                    {suggestions.map((suggestion: any, i: number) => {
                      let dropdownItemValue = `${suggestion.terms[0].value}, ${suggestion.terms[1].value}`

                      if (
                        this.props.isPlacesAutocompleteABTest === true &&
                        suggestion.terms[suggestion.terms.length - 1].value == 'USA'
                      ) {
                        if (suggestion.types.includes('postal_code')) {
                          dropdownItemValue = `${suggestion.terms[0].value}, ${suggestion.terms[1].value}, ${suggestion.terms[2].value}`
                          suggestion[
                            'displayText'
                          ] = `${suggestion.terms[0].value}, ${suggestion.terms[1].value}`
                        } else {
                          if (suggestion.terms.length == 2) {
                            dropdownItemValue = `${suggestion.terms[0].value}, ${suggestion.terms[1].value}`
                          } else {
                            dropdownItemValue = `${suggestion.terms[0].value}, ${
                              suggestion.terms[suggestion.terms.length - 2].value
                            }`
                          }
                          suggestion['displayText'] = dropdownItemValue
                        }
                      }

                      if (returnType && returnType === 'postal_code') {
                        dropdownItemValue = `${suggestion.terms[2].value}, ${suggestion.terms[0].value}, ${suggestion.terms[1].value}`
                        suggestion.displayText = suggestion.terms[2].value
                      }

                      return (
                        <div
                          css={[
                            LocationListItemStyles,
                            i === suggestions.length - 1
                              ? css`
                                  margin-bottom: 0;
                                `
                              : null
                          ]}
                          {...getSuggestionItemProps(suggestion)}
                          key={i}
                        >
                          <span>
                            {suggestion.active ? (
                              <Bold>{dropdownItemValue}</Bold>
                            ) : (
                              dropdownItemValue
                            )}
                          </span>
                        </div>
                      )
                    })}
                    {suggestions.length > 0 ? <PowerByGoogle /> : null}
                  </div>
                ) : null}
              </div>
            )
          }}
        </PlacesAutocomplete>
      </>
    )
  }
}

LocationInputUi.displayName = 'LocationInputUi'

export default LocationInputUi
