import React from 'react'
import { css } from '@emotion/core'
import Input, { InputProps } from './Input'
import { BLUE, GRAY_LIGHT, BLACK, MAIN_FONT_FAMILY } from '../../../constants/theme.styles'

const InputStyles = css`
  border: 1px solid ${GRAY_LIGHT};
  color: ${BLACK};
  font-family: ${MAIN_FONT_FAMILY};

  :focus {
    caret-color: ${BLUE};
    outline: none;

    &::-webkit-input-placeholder {
      color: ${BLACK};
    }
    &:-moz-placeholder {
      color: ${BLACK};
    }
    &::-moz-placeholder {
      color: ${BLACK};
    }
    &:-ms-input-placeholder {
      color: ${BLACK};
    }
  }

  &:focus {
    caret-color: ${BLUE};
    outline: none;
    + .inputIconWrapper {
      svg,
      path,
      g {
        fill: ${BLUE};
      }
    }
  }
  :active {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    background-color: #fff !important;
    box-shadow: inset 0 0 0px 9999px white !important;
  }
`

const IconStyles = css`
  opacity: 1;
`

const InputBold: React.FC<InputProps> = ({
  inputCss,
  wrapperCss,
  iconCss,
  ...props
}: InputProps) => {
  return (
    <Input
      inputCss={[InputStyles, inputCss]}
      wrapperCss={wrapperCss}
      iconCss={[IconStyles, iconCss]}
      {...props}
    />
  )
}

InputBold.displayName = 'Input'

export default InputBold
